import currencyToSymbolMap from 'currency-symbol-map/map';
import { useCurrencyFormatter } from '@wix/yoshi-flow-editor';
import { useCurrencyLocale } from './useCurrencyLocale';

const fallbackError = <T extends unknown>(callback: () => T, fallbackValue: T): T => {
  try {
    return callback();
  } catch {
    return fallbackValue;
  }
};

export function usePrice(
  value: string | undefined,
  currency: string | undefined,
): { price: string; currency: string; fullPrice: string } {
  const { locale } = useCurrencyLocale();
  const formatNumberParts = useCurrencyFormatter({ parts: true, language: locale });
  const formatFullPrice = useCurrencyFormatter({ language: locale });
  const numberParts = value && currency ? fallbackError(() => formatNumberParts({ value, currency }), []) : [];
  const fraction = numberParts.find((item) => item.type === 'fraction')?.value ?? '';
  const allZeroes = /^0+$/g.test(fraction);
  const formatPrice = useCurrencyFormatter({
    style: 'decimal',
    language: locale,
    minimumFractionDigits: allZeroes ? undefined : fraction.length,
  });

  return {
    price: value && currency ? fallbackError(() => formatPrice({ value, currency }), value) : value ?? '',
    currency:
      numberParts?.find((item) => item.type === 'currency')?.value ??
      (currency ? currencyToSymbolMap[currency.toUpperCase()] ?? currency : ''),
    fullPrice: value && currency ? fallbackError(() => formatFullPrice({ value, currency }), value) : value ?? '',
  };
}
